var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.session)?_c('div',[_c('h3',[_vm._v(_vm._s(_vm.$t("events.application.label")))]),(
        _vm.applicationClosed &&
        (!_vm.userApplication ||
          (_vm.userApplication && _vm.applicationStatus != 'confirmed'))
      )?_c('div',[_c('vca-card',{staticClass:"text-center"},[_c('div',{staticClass:"bold highlight"},[_vm._v(" "+_vm._s(_vm.$t("events.application.closed"))+" ")])])],1):(_vm.applicationBefore)?_c('div',[_c('vca-card',{staticClass:"text-center"},[_c('button',{staticClass:"vca-button",attrs:{"disabled":"","title":_vm.$t('button.apply')}},[_vm._v(" "+_vm._s(_vm.$t("button.apply"))+" ")]),_c('div',{staticClass:"bold highlight"},[_vm._v(" "+_vm._s(_vm.$t("events.application.before"))+" ")])])],1):_c('div',[(_vm.userParticipation && _vm.applicationStatus != 'withdrawn')?_c('vca-card',[(_vm.applicationStatus == 'rejected')?_c('div',{staticClass:"vca-center"},[_c('div',{staticClass:"bold highlight"},[_vm._v(" "+_vm._s(_vm.$t("events.application.rejected"))+" ")])]):(!_vm.eventClosed)?_c('div',{staticClass:"vca-center"},[_c('vca-cancel-button',{attrs:{"title":_vm.$t('context_button.revoke', {
                0: _vm.$t('events.application.header'),
              }),"placeholder":_vm.$t('context_button.revoke', {
                0: _vm.$t('events.application.label'),
              })},on:{"click":function($event){return _vm.withdraw()}}})],1):_vm._e()]):(!_vm.canApply)?_c('div',[_c('vca-card',{staticClass:"text-center"},[_c('button',{staticClass:"vca-button",attrs:{"disabled":"","title":_vm.$t('button.apply')}},[_vm._v(" "+_vm._s(_vm.$t("button.apply"))+" ")]),_c('div',{staticClass:"bold highlight"},[_vm._v(" "+_vm._s(_vm.$t("events.application.impossible"))+" ")])])],1):_c('div',[_c('div',[_c('h3',[_vm._v(_vm._s(_vm.$t("events.application.additional.label")))]),_c('vca-textarea',{attrs:{"placeholder":_vm.$t('events.application.additional.placeholder')},model:{value:(_vm.value.comment),callback:function ($$v) {_vm.$set(_vm.value, "comment", $$v)},expression:"value.comment"}})],1),(_vm.userParticipation && _vm.applicationStatus == 'withdrawn')?_c('div',[_c('div',[_vm._v(_vm._s(_vm.$t("events.application.apply_again")))]),_c('button',{staticClass:"vca-button",attrs:{"title":_vm.$t('button.apply')},on:{"click":function($event){return _vm.reapply()}}},[_vm._v(" "+_vm._s(_vm.$t("button.apply"))+" ")])]):_c('div',[_c('button',{staticClass:"vca-button",attrs:{"title":_vm.$t('button.apply')},on:{"click":function($event){return _vm.apply()}}},[_vm._v(" "+_vm._s(_vm.$t("button.apply"))+" ")])])])],1)]):_c('div',[_c('button',{staticClass:"vca-button",attrs:{"title":_vm.$t('events.button.register_apply')},on:{"click":function($event){return _vm.register()}}},[_vm._v(" "+_vm._s(_vm.$t("events.button.register_apply"))+" ")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }